import { usePageData } from 'react-app/context/page-data';
import ImageGallery from 'components/ImageGallery';
import PageTabs from 'components/PageTabs';

export function useImageGalleryTabs(props) {
	const [{ pageId, sections }] = usePageData();
	const { galleries } = sections || {};

	const tabs = galleries ? (
		Object.entries(galleries).map(([id, item]) => ({
			label: item.title,
			content: <ImageGallery key={`${pageId}-${id}`} images={item.images} {...props}  mt={1} size={120}/>
		}))
	) : [];

	return tabs;
}

export default function ImageGalleryTabs(props) {
	const { tabs = [] } = props;

	return tabs.length > 0 && (
		<PageTabs
			fullWidth
			animated={false}
			keepMounted={false}
			scrollable
			hideSingleTab
			{...props}
			tabs={tabs}
		/>
	);
}
