import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from 'styles';
import Stack from '@mui/material/Stack';
import Img from 'react-app/components/Img';
import CardActionArea from 'components/CardActionArea';
import ImgCarouselDialog from 'components/ImgCarouselDialog';
import { useMemoCallback } from 'react-app/hooks';
import ZoomIcon from '@mui/icons-material/ZoomOutMap';
import * as images from 'config/images';

const useClasses = createClasses((theme) => ({
	root: {
		width: '100%',
		display: 'grid',
		gridGap: theme.spacing(1),
		gridAutoFlow: 'dense'
	},
	item: {
		borderRadius: `${theme.shape.borderRadius}px`,
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		color: 'black',
		minHeight: '100%',
		maxHeight: 0,
		[theme.config.mediaQueries.noTouch]: {
			'&:hover > .MuiSvgIcon-root': {
				opacity: 1,
				transform: 'scale(1)'
			},
			'&:hover > .MuiCardActionArea-focusHighlight': {
				opacity: .6
			},
			'&:hover img': {
				transform: 'scale(1.1)'
			}
		}
	},
	focusHighlight: {
		transition: theme.transitions.create(['opacity'], {
			easing: theme.transitions.easing.easeIn,
			duration: theme.transitions.duration.short
		})
	},
	image: {
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		backgroundColor: theme.vars.palette.background.placeholder,
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem'
		},
		'& > img': {
			transition: theme.transitions.create(['transform'], {
				easing: theme.transitions.easing.easeIn,
				duration: theme.transitions.duration.short
			}),
			width: '100%',
			height: '100%',
			objectFit: 'cover',
			objectPosition: 'center'
		}
	},
	icon: {
		transition: theme.transitions.create(['opacity', 'transform'], {
			easing: theme.transitions.easing.easeIn,
			duration: theme.transitions.duration.standart
		}),
		position: 'absolute',
		margin: 'auto',
		fontSize: '3rem',
		color: 'rgba(255, 255, 255, .8)',
		zIndex: 1,
		transform: 'scale(1.2)',
		opacity: 0,
		[theme.config.mediaQueries.touch]: {
			opacity: 1,
			fontSize: '2.5rem',
			transform: 'scale(1)',
			top: theme.spacing(1.5),
			right: theme.spacing(1.5),
			padding: theme.spacing(1),
			backgroundColor: 'rgba(0,0,0,.6)',
			borderRadius: '50%'
		}
	}
}), {
	name: 'ImageGallery'
});

function ImageGallery(props) {
	const {
		classes: classesProp,
		className,
		size = 90,
		thumbSize = 282,
		images: imagesProp = [],
		ImgCarouselDialogPorps,
		gridTemplateColumns,
		gridAutoRows,
		...rest
	} = props;

	const classes = useClasses(props);

	const [dialogState, setDialogState] = React.useState({
		defaultCursor: 0,
		open: false
	});

	const handleDialogOpen = useMemoCallback((key) => (event) => {
		setDialogState({
			...dialogState,
			defaultCursor: key,
			open: true
		});
	});

	const handleDialogClose = useMemoCallback((event) => {
		setDialogState({
			...dialogState,
			open: false
		});
	});

	const imagesData = Array.isArray(imagesProp) && imagesProp.length ? imagesProp.map(imageData => {
		const {
			id,
			imageId,
			title,
			cols = 2,
			rows = 2
		} = imageData || {};

		const image = images[imageId || `gallery_${id}`] || images[id];
		const { width, height, src: imageSrc } = image?.xl || {};

		return {
			width,
			height,
			imageSrc,
			imageSrcThumb: image?.xs?.src || imageSrc,
			cols,
			rows,
			title
		};
	}) : null;

	if (!imagesData) {
		return null;
	}

	return (
		<>
			<Stack
				{...rest}
				className={classes.root}
				gridTemplateColumns={{
					xs: 'repeat(auto-fit, minmax(72px, 1fr))',
					sm: `repeat(auto-fit, minmax(${size}px, 1fr))`,
					...gridTemplateColumns
				}}
				gridAutoRows={{
					xs: 'minmax(72px, auto)',
					sm: `minmax(${size}px, auto)`,
					...gridAutoRows
				}}
			>
				{imagesData.map((item, key) => (
					<CardActionArea
						key={key}
						className={classes.item}
						sx={{
							width: `min(${thumbSize}px, 100%)`,
							gridColumnEnd: `span ${item.cols || 1}`,
							gridRowEnd: `span ${item.rows || 1}`
						}}
						title={item.title}
						onClick={handleDialogOpen(key)}
						data-src={item.imageSrc}
					>
						<Img
							fullWidth
							width={item.width}
							height={item.height}
							className={classes.image}
							src={item.imageSrcThumb}
							alt={item.title || item.imageSrcThumb}
							caption={item.title}
						/>
						<ZoomIcon className={classes.icon}/>
					</CardActionArea>
				))}
			</Stack>
			<ImgCarouselDialog
				{...ImgCarouselDialogPorps}
				onClose={handleDialogClose}
				images={imagesData}
				{...dialogState}
			/>
		</>
	);
}

ImageGallery.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string
};

export default React.memo(ImageGallery);
