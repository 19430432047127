import * as React from 'react';
import PropTypes from 'prop-types';
import PageSection from 'components/PageSection';

function CarouselSection(props) {
	const {
		children,
		cards = [],
		CarouselComponentProps,
		CarouselComponent = 'div',
		CardProps,
		CardComponent,
		...rest
	} = props;

	return (
		<PageSection {...rest} disableInnerGuttersX>
			<CarouselComponent
				CardProps={CardProps}
				CardComponent={CardComponent}
				cards={cards}
				{...CarouselComponentProps}
			/>
			{children}
		</PageSection>
	);
}

CarouselSection.propTypes = {
	cards: PropTypes.array.isRequired,
	variant: PropTypes.string,
	carouselMode: PropTypes.bool,
	PageHeadlineProps: PropTypes.object,
	CarouselComponentProps: PropTypes.object,
	CarouselComponent: PropTypes.elementType.isRequired,
	CardProps: PropTypes.object,
	CardComponent: PropTypes.elementType
};

export default React.memo(CarouselSection);
