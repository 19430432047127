import * as React from 'react';
import { useTheme } from 'styles';
import Stack from '@mui/material/Stack';
import PageHeadline from 'components/PageHeadline';
import PageContainer from 'components/PageContainer';

function PageSection(props) {
	const {
		children,
		title,
		subtitle,
		ContainerProps,
		PageHeadlineProps,
		disableInnerGuttersX,
		disableInnerGuttersY,
		...rest
	} = props;

	const theme = useTheme();

	return (
		<PageContainer gutterTop {...rest}>
			<Stack
				width="100%"
				paddingX={disableInnerGuttersX ? 0 : theme.config.containerSpacing}
				paddingY={disableInnerGuttersY ? 0 : theme.config.containerSpacing}
				backgroundColor={theme.vars.palette.background.overlay}
				borderRadius={`${theme.shape.borderRadius}px`}
				{...ContainerProps}
			>
				{(title || subtitle) && (
					<PageHeadline
						component="h2"
						{...PageHeadlineProps}
						title={title}
						subtitle={subtitle}
						disableGuttersX={!disableInnerGuttersX}
					/>
				)}
				{children}
			</Stack>
		</PageContainer>
	);
}

export default React.memo(PageSection);
