import * as React from 'react';
import PropTypes from 'prop-types';
import { usePrevious } from '../../hooks';

function DelayUnmounting(props) {
	const {
		children,
		isMounted = false,
		onMount,
		onUnmount,
		delay = 500
	} = props;

	const [shouldRender, setShouldRender] = React.useState(isMounted);

	const timerRef = React.useRef();
	const dispatchRef = React.useRef();

	const prevIsMountend = usePrevious(isMounted);

	React.useEffect(() => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}

		if (dispatchRef.current) {
			dispatchRef.current();
			dispatchRef.current = null;
		}

		if (shouldRender && prevIsMountend && !isMounted) {
			timerRef.current = setTimeout(() => {
				setShouldRender(false);
				dispatchRef.current = () => {
					// window.dispatchEvent(new CustomEvent('delayUnmounting:unmount'));
					if (typeof onUnmount === 'function') {
						onUnmount();
					}
				};
			}, delay);
		} else if (!shouldRender && !prevIsMountend && isMounted) {
			setShouldRender(true);
			dispatchRef.current = () => {
				// window.dispatchEvent(new CustomEvent('delayUnmounting:mount'));
				if (typeof onMount === 'function') {
					onMount();
				}
			};
		}

		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [
		delay,
		isMounted,
		onMount,
		onUnmount,
		prevIsMountend,
		shouldRender
	]);

	return shouldRender ? children : null;
}

DelayUnmounting.propTypes = {
	children: PropTypes.node.isRequired,
	delay: PropTypes.number.isRequired,
	isMounted: PropTypes.bool.isRequired,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func
};

export default React.memo(DelayUnmounting);
