import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import PhoneIcon from 'react-app/icons/Phone';
import LocationIcon from '@mui/icons-material/LocationOn';
import Avatar from 'react-app/components/Avatar';
import Link from 'components/Link';

const styles = {
	icon: {
		verticalAlign: 'middle',
		marginRight: 1
	},
	text: {
		display: 'flex',
		fontSize: {xs: '0.875rem', sm: '1rem', lg: '1.125rem'},
		textAlign: 'left',
		alignItems: 'center',
		alignContent: 'center',
		lineHeight: 1.25,
		gap: .5
	}
};

export default function ContactSection(props) {
	const {
		name,
		phone,
		mobile,
		email,
		map,
		company,
		address,
		imageSrc
	} = props;

	return (
		<Stack minWidth={{sm: 460}} direction={{sm: 'row'}} alignItems={{xs: 'center', sm: 'flex-start'}} gap={{xs: 2, md: 4}}>
			<Avatar
				width={160}
				height={160}
				src={imageSrc}
			/>
			<Stack marginY="auto" gap={{xs: .5, md: .75}}>
				{name && (
					<Typography
						variant="h4"
						fontWeight="bold"
						fontSize={{xs: '1.25rem', sm: '1.5rem', md: '1.75rem'}}
						marginBottom={{xs: .5, md: 1}}
					>
						{name}
					</Typography>
				)}
				{company && address && map && (
					<address>
						<Typography
							sx={styles.text}
							color="inherit"
							fontSize="inherit"
							noWrap
						>
							<LocationIcon sx={styles.icon} fontSize="inherit"/>
							<Link color="text.primary" href={map}>
								{company}<br/>
								{address}
							</Link>
						</Typography>
					</address>
				)}
				{phone && (
					<Typography
						sx={styles.text}
						color="inherit"
						fontSize="inherit"
						noWrap
					>
						<PhoneIcon sx={styles.icon} fontSize="inherit"/>
						<Link color="text.primary" href={`tel:${phone}`}>
							{phone}
						</Link>
					</Typography>
				)}
				{mobile && (
					<Typography
						sx={styles.text}
						color="inherit"
						fontSize="inherit"
						noWrap
					>
						<SmartphoneIcon sx={styles.icon} fontSize="inherit"/>
						<Link color="text.primary" href={`tel:${mobile}`}>
							{mobile}
						</Link>
					</Typography>
				)}
				{email && (
					<Typography
						sx={styles.text}
						color="inherit"
						fontSize="inherit"
						noWrap
					>
						<EmailIcon sx={styles.icon} fontSize="inherit"/>
						<Link color="text.primary" href={`mailto:${email}`}>
							{email}
						</Link>
					</Typography>
				)}
			</Stack>
		</Stack>
	);
}
