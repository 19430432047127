import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from 'styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import Link from 'components/Link';
import SlidableViews from 'react-app/components/SlidableViews';
import { useBrowser } from 'react-app/context/browser';
import { useWillUnmount } from 'react-app/hooks';
import { scrollToElementStart } from 'lib/helpers';

const TAB_HEIGHT = 49;

const defaultProps = {
	unmountOnExit: true,
	animated: true,
	initialTab: 0,
	keepMounted: true,
	tabColor: 'inherit'
};

const useClasses = createClasses((theme, {scrollable, sticky, tabColor = defaultProps.tabColor}) => ({
	root: {
		width: '100%',
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column'
	},
	tab: {
		transition: theme.transitions.create(['background'], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.complex
		}),
		'&:hover': {
			textDecoration: 'none',
			backgroundColor: theme.helpers.alpha('text-primary', .05)
		}
	},
	tabs: {
		width: '100%',
		alignSelf: 'center',
		borderBottom: `solid 1px ${theme.vars.palette.divider}`,
		...(sticky && {
			marginLeft: 'auto',
			marginRight: 'auto',
			position: 'sticky',
			zIndex: theme.zIndex.appBar - 1,
			backgroundColor: theme.helpers.alpha('background-default', .8),
			...(theme.mixins.backdropBlur(theme.spacing(theme.spacing(1)))),
			height: TAB_HEIGHT,
			top: 'var(--app-bar-height)'
		})
	},
	tabSelected: {
		'&:hover': {
			backgroundColor: theme.helpers.alpha(theme.vars.palette[tabColor]?.main ? `${tabColor}-main` : 'text-primary', .05)
		}
	},
	tabsFlexContainer: {
		justifyContent: 'center',
		...(scrollable && {
			justifyContent: 'flex-start',
			margin: '0 auto',
			display: 'inline-flex',
			width: 'auto'
		})
	},
	tabsScroller: {
		...theme.mixins.scrollbar,
		overflow: 'auto',
		...(scrollable && {
			display: 'inline-flex'
		})
	}
}), {
	name: 'PageTabs'
});

function PageTabs(props) {
	const {
		classes: classesProp,
		className,
		children,
		tabs = [],
		animated = defaultProps.animated,
		fullWidth,
		initialTab = defaultProps.initialTab,
		unmountOnExit = defaultProps.unmountOnExit,
		keepMounted = defaultProps.keepMounted,
		swipeable,
		TabsProps,
		TabProps,
		SlidableViewsProps,
		noTransform,
		sticky,
		scrollable,
		disableDivider,
		tabColor = defaultProps.tabColor,
		waitForMounting: waitForMountingProp,
		hideSingleTab,
		...rest
	} = props;

	const classes = useClasses(props);

	const browser = useBrowser();
	const { isWidthUp } = browser;
	const [value, setValue] = React.useState(initialTab >= 0 && initialTab <= tabs.length ? initialTab : 0);
	const [mountedTabsState, setMountedTabsState] = React.useState(keepMounted ? [value] : []);
	const mountedTabsRef = React.useRef(mountedTabsState);

	const reqIdRef = React.useRef();
	const contentRef = React.useRef();

	const waitForMounting = waitForMountingProp || swipeable;
	const hasTabs = Array.isArray(tabs) && tabs.length > 0;
	const showTabs = Boolean(hasTabs && (!hideSingleTab || tabs.length > 1));

	const handleChange = React.useCallback((event, newValue) => {
		if (event?.target?.href || event?.target?.parentNode?.href) {
			return;
		}

		const changeTab = () => {
			reqIdRef.current = window.requestAnimationFrame(() => {
				if (sticky) {
					scrollToElementStart(contentRef?.current, TAB_HEIGHT);
				}
				setValue(newValue);
			});
		};

		const mountedTabs = keepMounted ? [
			...mountedTabsState
		] : [];

		if (keepMounted && !mountedTabs.includes(newValue)) {
			mountedTabs.push(newValue);
			if (waitForMounting) {
				setMountedTabsState(mountedTabs);
			} else {
				mountedTabsRef.current.push(newValue);
			}
		}

		changeTab();
	}, [mountedTabsState, keepMounted, sticky, waitForMounting]);

	const handleChangeCursor = React.useCallback((index) => {
		handleChange(null, index);
	}, [handleChange]);

	useWillUnmount(() => {
		window.cancelAnimationFrame(reqIdRef.current);
	});

	const smUp = isWidthUp('sm', browser.breakpoint);

	return (
		<>
			{showTabs && (
				<Tabs
					scrollButtons
					allowScrollButtonsMobile
					textColor={tabColor}
					indicatorColor={tabColor}
					{...TabsProps}
					value={value}
					variant={scrollable ? 'scrollable' : !smUp ? 'fullWidth' : null}
					onChange={handleChange}
					classes={{
						root: classes.tabs,
						flexContainer: classes.tabsFlexContainer,
						scroller: classes.tabsScroller,
						...TabsProps?.classes
					}}
				>
					{tabs.map((tab, key) => (
						<Tab
							{...TabProps}
							key={key}
							classes={{
								root: classes.tab,
								selected: classes.tabSelected,
								...TabProps?.classes
							}}
							label={tab.label}
							{...(tab.to && {
								href: tab.to,
								component: Link
							})}
						/>
					))}
				</Tabs>
			)}

			<Stack
				ref={contentRef}
				className={classes.root}
				marginTop={(theme) => showTabs ? theme.config.containerSpacing : 0}
			>
				<SlidableViews
					value={value}
					swipeable={swipeable}
					mountedSlides={waitForMounting ? mountedTabsState : mountedTabsRef.current}
					onChangeCursor={swipeable ? handleChangeCursor : null}
					{...SlidableViewsProps}
					noTransform={noTransform}
					animated={animated}
				>
					{Array.isArray(tabs) && tabs.map((tab, key) => (
						<Stack
							width="100%"
							{...rest}
							key={key}
						>
							{tab.content || null}
						</Stack>
					))}
				</SlidableViews>
			</Stack>
		</>
	);
}

PageTabs.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	animated: PropTypes.bool,
	fullWidth: PropTypes.bool,
	initialTab: PropTypes.number,
	tabs: PropTypes.array.isRequired,
	keepMounted: PropTypes.bool,
	TabsProps: PropTypes.object,
	TabProps: PropTypes.object,
	SlidableViewsProps: PropTypes.object,
	noTransform: PropTypes.bool,
	sticky: PropTypes.bool,
	scrollable: PropTypes.bool,
	waitForMounting: PropTypes.bool,
	disableDivider: PropTypes.bool,
	hideSingleTab: PropTypes.bool,
	tabColor: PropTypes.oneOf(['inherit', 'primary', 'secondary'])
};

export default React.memo(PageTabs);
